
import InconsistencyAlertService from '@/common/services/InconsistencyAlert';
import mixins from 'vue-typed-mixins';
import { mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';
import { VForm, InconsistencyAlert } from '@/types';

export default mixins(NotificationMixin).extend({
  name: 'TaskManagerInconsistencyAlertModal',
  components: {
  },
  props: {
    showModal: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  data() {
    return {
      header: this.$t('taskManager.inconsistencyAlert.header'),
      description: this.$t('taskManager.inconsistencyAlert.description'),
      input: '',
      emails: [] as string[],
      tableHeader: {
        alias: [
          this.$t('taskManager.inconsistencyAlert.table.alias.email'),
        ],
        columnsToShow: [
          'email',
        ],
      },
      indexItemEditing: undefined as undefined | number,
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean): void {
        this.$emit('update:showModal', value);
      },
    },
    inputEqualEditValue(): boolean {
      if (this.indexItemEditing !== undefined) {
        return this.input === this.emails[this.indexItemEditing];
      }
      return false;
    },
    emailsIncludeInput(): boolean {
      return this.emails.includes(this.input);
    },
  },
  async mounted() {
    this.handleLoading(true);

    this.searchInconsistencyAlert();

    this.handleLoading(false);
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async searchInconsistencyAlert() {
      const { masterId } = this.$store.state.auth.userCompany;

      const response = await InconsistencyAlertService.getInconsistencyAlert(masterId);

      if (response) {
        this.emails = [
          ...response.alertEmails,
        ];
      } else {
        this.emails = [];
      }
    },
    async createOrUpdateInconsistencyAlert(): Promise<void> {
      const payload = {
        masterId: this.$store.state.auth.userCompany.masterId,
        alertEmails: this.emails,
      } as InconsistencyAlert;

      try {
        await InconsistencyAlertService.createOrUpdateInconsistencyAlert(payload);
        this.closeModal();

        this.successToast({
          text: this.$t('taskManager.inconsistencyAlert.requestSuccess') as string,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async updateEmailsList(): Promise<void> {
      const form = this.$refs.form as VForm;
      const isValid = await form.validate();

      if (
        isValid
        && (!this.emailsIncludeInput || this.inputEqualEditValue)
      ) {
        if (this.indexItemEditing !== undefined) {
          this.emails.splice(this.indexItemEditing, 1, this.input);
          this.indexItemEditing = undefined;
          this.input = '';
          return;
        }

        this.emails.push(this.input);
        this.input = '';
      }
    },
    editItem(item: string): void {
      this.indexItemEditing = this.emails.findIndex(
        (value: string) => value === item,
      );
      this.input = item;
    },
    removeItem(item: string): void {
      const indexItem = this.emails.findIndex(
        (value: string) => value === item,
      );

      this.emails.splice(indexItem, 1);
    },
    closeModal() {
      this.input = '';
      this.displayModal = false;
    },
  },
});
