import axios from '@/common/http';
import { InconsistencyAlert } from '@/types';

class InconsistencyAlertService {
    private service: typeof axios = axios;

    private baseUrl = `${process.env.VUE_APP_TASK_SCHEDULER_URL_GATEWAY}`;

    public async createOrUpdateInconsistencyAlert(data: InconsistencyAlert) {
      return (await this.service.post(`${this.baseUrl}/notifications`, data)).data;
    }

    public async getInconsistencyAlert(masterID: string): Promise<InconsistencyAlert> {
      const query = {
        masterId: masterID,
      };
      return (await this.service.get(`${this.baseUrl}/notifications`, { params: query })).data.data;
    }

    public async updateInconsistencyAlert(data: InconsistencyAlert, inconsistencyAlertId: string) {
      return (await this.service.patch(`${this.baseUrl}/notifications/${inconsistencyAlertId}`, data)).data;
    }
}

export default new InconsistencyAlertService();
